import { LocalizationProvider } from '@mui/lab';
import { CssBaseline, StyledEngineProvider, Theme, ThemeProvider } from '@mui/material';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import _ from 'lodash';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { AuthContainer } from './containers/AuthContainer';
import { AppConfig, AppConfigType, getAppConfig } from './model/appConfig';
import { queryClient } from './queries';
import theme from './theme';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

if (
  process.env.REACT_APP_BUILD_TARGET === undefined ||
  !_(AppConfigType).keys().includes(process.env.REACT_APP_BUILD_TARGET)
) {
  throw new Error(`Unsupported AppConfigType: ${process.env.REACT_APP_BUILD_TARGET ?? 'undefined'}`);
}
const appConfigType = AppConfigType[process.env.REACT_APP_BUILD_TARGET as keyof typeof AppConfigType];
const appConfig = getAppConfig(appConfigType);

async function importBuildTarget() {
  if (appConfigType === AppConfigType.SSER) {
    return import('./App');
  } else if (appConfigType === AppConfigType.SAHARA) {
    return import('./BuysideApp');
  } else {
    throw new Error('This should never be reached');
  }
}

export interface AppProps {
  appConfig: AppConfig;
}

const amplifyConfig = {
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID!,
  domain: process.env.REACT_APP_COGNITO_APP_DOMAIN!,
  redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN!,
  redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT!,
};

void (async () => {
  const { default: App } = await importBuildTarget();
  ReactDOM.render(
    <React.StrictMode>
      <AuthContainer amplifyConfig={amplifyConfig} appConfig={appConfig}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <QueryClientProvider client={queryClient}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <DndProvider backend={HTML5Backend}>
                  <App appConfig={appConfig} />
                </DndProvider>
              </ThemeProvider>
            </StyledEngineProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </LocalizationProvider>
      </AuthContainer>
    </React.StrictMode>,
    document.getElementById('root'),
  );
})();
