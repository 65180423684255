import { Auth } from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import ky from 'ky';

/**
 * Get the JWT from the current Amplify session if available.
 * @return The JWT string if there is an active session, otherwise undefined
 */
export async function getToken(): Promise<string | undefined> {
  try {
    const session: CognitoUserSession = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch {
    return undefined;
  }
}

const httpClient = ky.create({
  hooks: {
    beforeRequest: [
      async (request) => {
        const token = await getToken();
        if (token) {
          request.headers.set('Authorization', `Bearer ${token}`);
        }
      },
    ],
  },
});

export default httpClient;
