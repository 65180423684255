import { PaginationItemClassKey } from '@mui/lab/PaginationItem';
import { createTheme, LinkProps } from '@mui/material';
import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

declare module '@mui/material/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiPaginationItem: PaginationItemClassKey;
  }
}

const LinkBehavior = React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
  (props, ref) => {
    const { href, ...other } = props;
    return <RouterLink ref={ref} to={href} {...other} />;
  },
);

// Consistent color palette provided by design
export const Colors = {
  Transparent: 'transparent',
  White: '#FFF',
  TextDark: '#11202B',
  Gray: {
    1: '#F4F6F9',
    2: '#E9ECF2',
    3: '#D1D5D9',
    4: '#B2B9C4',
    5: '#718096',
    6: '#33424E',
    7: '#37444E',
    101: '#68778D', // Unnamed in design but reused
  },
  Green: {
    1: '#E4FAF2',
    2: '#C6DBDB',
    3: '#008585',
    4: '#E2F5F1',
    101: '#7ED6BC',
    102: '#BAE8D4',
  },
  Red: {
    1: '#FAF0F1',
    2: '#E3CDD0',
    3: '#D13247',
    4: '#8B0000',
    101: '#FCC2C5',
    102: '#FFE3E3',
    103: '#b43247',
  },
  RedTransparent: 'rgba(209,50,71,0.08)',
  Blue: {
    1: '#E6F6FF',
    2: '#BFD4E0',
    3: '#0F7DBD',
    4: '#0B4E75',
    101: '#75a1e4',
  },
  Yellow: {
    101: '#E3BB11',
    102: '#FAB41C',
  },
  Purple: {
    1: '#F9F0FA',
    2: '#6229C4',
    3: '#6B1974',
  },
  Orange: {
    1: '#FAF0E4',
    3: '#DC7700',
  },
  Gold: '#DCA200',
  Silver: '#718096',
  Bronze: '#97560A',
};

const theme = createTheme({
  typography: {
    htmlFontSize: 10,
    subtitle1: {
      fontSize: '1.6rem',
    },
    subtitle2: {
      fontSize: '1.4rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1.4rem',
    },
    body2: {
      fontSize: '1.1rem',
    },
  },
  palette: {
    background: {
      default: Colors.White,
    },
    primary: {
      main: Colors.Blue[3],
    },
    secondary: {
      main: Colors.Gray[5],
    },
    text: {
      primary: Colors.TextDark,
      secondary: Colors.Gray[5],
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          a: {
            color: Colors.Blue[3],
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: Colors.TextDark,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          textTransform: 'none',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiPaginationItem: {
      styleOverrides: {
        outlinedPrimary: {
          margin: '0 -0.5px',
          borderRadius: 0,
          borderColor: Colors.Gray[3],
          backgroundColor: Colors.White,
          color: Colors.Blue[3],
          '&$selected': {
            color: Colors.Gray[6],
            borderColor: Colors.Gray[3],
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& tr': {
            backgroundColor: Colors.Gray[1],
            borderTop: `1px solid ${Colors.Gray[2]}`,
          },
          '& th': {
            fontSize: '1.2rem',
            paddingTop: 5,
            paddingBottom: 5,
            color: Colors.Gray[6],
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '& td': {
            fontSize: '1.2rem',
            paddingTop: 10,
            paddingBottom: 10,
            color: Colors.Gray[6],
          },
        },
      },
    },
  },
});

export default theme;
